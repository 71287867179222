<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.minStayEditForm"
    />
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Minimum Stay View/Edit</div>
      </div>
      <div class="card-body">
        <div v-if="loading" class="my-5 text-center">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
        <div v-else>
          <ValidationObserver ref="minStayEditForm">
            <form @submit.prevent="onSubmit">
              <AdminCommonBusinessRuleFields
                :view="boolView"
                :rule="minStayRule"
                :referenceId="refId"
              />
              <hr />
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    :rules="lengthRule"
                    name="Length"
                    id="ruleLength"
                    placeholder="Enter length"
                    v-model="minStayRule.minimumStayLength"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <ValidationProvider
                    rules="required"
                    name="Unit"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="ruleUnit"
                    >
                      Unit
                      <span class="error">*</span>
                    </label>
                    <select
                      class="form-control form-control-lg"
                      id="ruleUnit"
                      v-model="minStayRule.unitId"
                      v-bind="ariaInput"
                      :disabled="boolView"
                    >
                      <option
                        v-for="(choice, index) in unitChoices"
                        :key="index"
                        :value="choice.id"
                      >
                        {{ choice.name }}
                      </option>
                    </select>
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                  <small class="form-text text-muted mb-2"
                    >Units of time to apply to length.</small
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:0,7"
                    name="Monday Length"
                    id="ruleMondayLength"
                    placeholder="Enter Monday length"
                    v-model="minStayRule.minimumStayMonday"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Monday.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:0,7"
                    name="Tuesday Length"
                    id="ruleTuesdayLength"
                    placeholder="Enter Tuesday length"
                    v-model="minStayRule.minimumStayTuesday"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Tuesday.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:0,7"
                    name="Wednesday Length"
                    id="ruleWednesdayLength"
                    placeholder="Enter Wednesday length"
                    v-model="minStayRule.minimumStayWednesday"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Wednesday.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:0,7"
                    name="Thursday Length"
                    id="ruleThursdayLength"
                    placeholder="Enter Thursday length"
                    v-model="minStayRule.minimumStayThursday"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Thursday.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:0,7"
                    name="Friday Length"
                    id="ruleFridayLength"
                    placeholder="Enter Friday length"
                    v-model="minStayRule.minimumStayFriday"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Friday.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:0,7"
                    name="Saturday Length"
                    id="ruleSaturdayLength"
                    placeholder="Enter Saturday length"
                    v-model="minStayRule.minimumStaySaturday"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Saturday.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:0,7"
                    name="Sunday Length"
                    id="ruleSundayLength"
                    placeholder="Enter Sunday length"
                    v-model="minStayRule.minimumStaySunday"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Sunday.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12"></div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:1,9"
                    name="Memorial Day Length"
                    id="ruleHolidayLength"
                    placeholder="Enter Memorial Day length"
                    v-model="minStayRule.minimumStayMemorialDay"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Memorial Day.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:1,9"
                    name="July 4th Length"
                    id="ruleHolidayLength"
                    placeholder="Enter July 4th length"
                    v-model="minStayRule.minimumStayJuly4"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a July 4th.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:1,9"
                    name="Labor Day Length"
                    id="ruleHolidayLength"
                    placeholder="Enter Labor Day length"
                    v-model="minStayRule.minimumStayLaborDay"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Labor Day.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="between:1,9"
                    name="Columbus Day Length"
                    id="ruleHolidayLength"
                    placeholder="Enter Columbus Day length"
                    v-model="minStayRule.minimumStayColumbusDay"
                    :disabled="boolView"
                  />
                  <small class="form-text text-muted mb-2">
                    Minimum units of time allowed in a single reservation
                    starting on a Columbus Day.
                  </small>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Created By"
                    id="createdBy"
                    class="mb-2"
                    v-model="minStayRule.createdBy"
                    :disabled="true"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Date Created"
                    id="dateCreated"
                    class="mb-2"
                    v-model="minStayRule.dateCreated"
                    :disabled="true"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Last Modified By"
                    id="lastModifiedBy"
                    class="mb-2"
                    v-model="minStayRule.lastModifiedBy"
                    :disabled="true"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Date Last Modified"
                    id="dateLastModified"
                    class="mb-2"
                    v-model="minStayRule.dateLastModified"
                    :disabled="true"
                  />
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-primary mt-3"
                v-show="!boolView"
              >
                Update
              </button>
              <button
                type="button"
                class="btn btn-lg btn-primary mt-3"
                v-show="boolView"
                @click="edit"
              >
                Edit
              </button>
              <button
                class="btn btn-lg btn-secondary mt-3 ml-1"
                type="button"
                @click="goBack"
              >
                Back
              </button>
              <button
                class="btn btn-cancel btn-lg mt-3 ml-1"
                type="button"
                v-show="!boolView"
                @click="cancel"
              >
                Cancel
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AdminCommonBusinessRuleFields from "@/components/admin/AdminCommonBusinessRuleFields.vue";
import AdminBusinessRuleService from "@/services/admin/AdminBusinessRuleService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminMinimumStayView",
  title: "Admin - Minimum Stay View/Edit",
  components: {
    ValidationObserver,
    ValidationProvider,
    AdminCommonBusinessRuleFields,
    FormErrorAlert,
    TextInput
  },
  props: {
    view: String,
    id: String,
    refId: String
  },
  data() {
    return {
      minStayRule: {},
      unitChoices: [],
      booleanChoices: [
        { key: "Yes", value: true },
        { key: "No", value: false }
      ],
      errors: [],
      availableProductClasses: [],
      loading: false,
      boolView: true
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    lengthRule() {
      const rule =
        this.minStayRule.unitId === "2"
          ? "required|between:1,13"
          : "required|between:1,366";
      return rule;
    }
  },
  methods: {
    goBack() {
      this.$router.push("/admin/brm/minimum-stay").catch(() => {});
    },
    edit() {
      this.boolView = false;
      this.$router
        .push(`/admin/brm/minimum-stay/${this.id}?refId=${this.refId}&v=false`)
        .catch(() => {});
    },
    cancel() {
      this.loadRule();
      this.boolView = true;
    },
    async initialize() {
      const dataService = new AdminLookupService(this.tenantId);
      const productService = new AdminProductManagementService(this.tenantId);
      const units = dataService.getAllUnits();
      const classes = productService.getAllProductClasses();
      await Promise.allSettled([units, classes]).then(responses => {
        this.unitChoices = responses[0].value;
        this.availableProductClasses = responses[1].value;
      });
    },
    async loadRule() {
      this.loading = true;
      await this.initialize();
      const brService = new AdminBusinessRuleService(this.tenantId);
      brService.getMinimumStay(this.id).then(response => {
        const {
          id,
          createdBy,
          lastModifiedBy,
          areaId,
          comments,
          isActive,
          minimumStayLength,
          minimumStayMonday,
          minimumStayTuesday,
          minimumStayWednesday,
          minimumStayThursday,
          minimumStayFriday,
          minimumStaySaturday,
          minimumStaySunday,
          minimumStayMemorialDay,
          minimumStayJuly4,
          minimumStayLaborDay,
          minimumStayColumbusDay,
          loopId,
          paymentTypeId,
          productId,
          productTypeId,
          salesChannelId,
          seasonTypeId,
          unitId,
          validationMessage,
          businessRuleProductClassifications
        } = response.data;

        let {
          startDate,
          endDate,
          dateCreated,
          dateLastModified
        } = response.data;

        startDate = this.$options.filters.formatUTCDate(
          startDate.toISOString()
        );
        endDate = this.$options.filters.formatUTCDate(endDate.toISOString());
        dateCreated = this.$options.filters.formatUTCDateTime(
          dateCreated.toISOString()
        );
        dateLastModified = this.$options.filters.formatUTCDateTime(
          dateLastModified.toISOString()
        );

        this.minStayRule = {
          name: "Minimum Stay",
          id,
          createdBy,
          dateCreated,
          lastModifiedBy,
          dateLastModified,
          areaId: areaId === null ? "" : areaId,
          comments,
          startDate,
          endDate,
          isActive,
          minimumStayLength,
          minimumStayMonday,
          minimumStayTuesday,
          minimumStayWednesday,
          minimumStayThursday,
          minimumStayFriday,
          minimumStaySaturday,
          minimumStaySunday,
          minimumStayMemorialDay,
          minimumStayJuly4,
          minimumStayLaborDay,
          minimumStayColumbusDay,
          loopId: loopId === null ? "" : loopId,
          paymentTypeId: paymentTypeId === null ? "" : paymentTypeId,
          productId: productId === null ? "" : productId,
          productTypeId: productTypeId === null ? "" : productTypeId,
          salesChannelId: salesChannelId === null ? "" : salesChannelId,
          seasonTypeId: seasonTypeId === null ? "" : seasonTypeId,
          unitId: unitId === null ? "" : unitId,
          validationMessage,
          productClasses: this.availableProductClasses.filter(n =>
            businessRuleProductClassifications.some(
              x => x.productClassificationId === n.id
            )
          )
        };

        this.loading = false;
      });
    },
    async onSubmit() {
      this.$refs.minStayEditForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.minStayEditForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.minStayEditForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          var request = {
            id: this.minStayRule.id,
            isActive: this.minStayRule.isActive,
            productId: this.minStayRule.productId,
            productTypeId: this.minStayRule.productTypeId,
            areaId: this.minStayRule.areaId,
            loopId: this.minStayRule.loopId,
            salesChannelId: this.minStayRule.salesChannelId,
            seasonTypeId: this.minStayRule.seasonTypeId,
            paymentTypeId: this.minStayRule.seasonTypeId,
            comments: this.minStayRule.comments,
            startDate: this.minStayRule.startDate,
            endDate: this.minStayRule.endDate,
            minimumStayLength: this.minStayRule.minimumStayLength,
            unitId: this.minStayRule.unitId,
            minimumStayMonday: this.minStayRule.minimumStayMonday,
            minimumStayTuesday: this.minStayRule.minimumStayTuesday,
            minimumStayWednesday: this.minStayRule.minimumStayWednesday,
            minimumStayThursday: this.minStayRule.minimumStayThursday,
            minimumStayFriday: this.minStayRule.minimumStayFriday,
            minimumStaySaturday: this.minStayRule.minimumStaySaturday,
            minimumStaySunday: this.minStayRule.minimumStaySunday,
            minimumStayMemorialDay: this.minStayRule.minimumStayMemorialDay,
            minimumStayJuly4: this.minStayRule.minimumStayJuly4,
            minimumStayLaborDay: this.minStayRule.minimumStayLaborDay,
            minimumStayColumbusDay: this.minStayRule.minimumStayColumbusDay,
            validationMessage: this.minStayRule.validationMessage
          };
          if (this.minStayRule.productClasses) {
            request.productClassIds = this.minStayRule.productClasses.map(
              n => n.id
            );
          }
          const brService = new AdminBusinessRuleService(this.tenantId);
          const response = await brService.editMinimumStay(request);
          window.scrollTo(0, 0);
          if (response?.statusCode == "Success") {
            this.loadRule();
            this.boolView = true;
            this.$router
              .push(
                `/admin/brm/minimum-stay/${this.id}?refId=${this.refId}&v=true`
              )
              .catch(() => {});
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-success",
              message: "Business Rule Updated",
              layer: "admin"
            });
          } else {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
          this.errors = [];
        }
      });
    }
  },
  created() {
    this.loadRule();
  }
};
</script>
